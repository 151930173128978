import { Route, Routes } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import CircularProgress from './components/CircularProgress';
import NavBar from './containers/NavBar';
import Footer from './containers/Footer';
import Support from './containers/Support';

const BuyTicket = lazy(() => import('./containers/Buyticket').then().catch());
const MyAccount = lazy(() => import('./containers/MyAccount').then().catch());

const routes = [{
    path: '/buyTickets',
    component: BuyTicket,
}, {
    path: '/buyTickets/payment/:id',
    component: BuyTicket,
}, {
    path: '/myAccount',
    component: MyAccount,
}];

const Router = () => {
    return (
        <div className="main_content">
            <NavBar/>
            <Suspense fallback={<div className="content_div"><CircularProgress/></div>}>
                <div className="content_div">
                    <Routes>
                        {routes.map((route) =>
                            <Route
                                key={route.path}
                                exact
                                element={<route.component/>}
                                path={route.path}/>,
                        )}
                        <Route
                            exact
                            element={<BuyTicket/>}
                            path="*"/>
                    </Routes>
                </div>
            </Suspense>
            <Support/>
            <Footer/>
        </div>
    );
};

export default Router;
