import React from 'react';
import './index.css';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import variables from '../../utils/variables';
import { ReactComponent as Logo } from '../../assets/all/omniflix-logo.svg';

const Footer = (props) => {
    return (
        <div className="footer">
            {variables[props.lang]['powered_by']}
            <Logo onClick={() => window.open('https://www.omniflix.network')}/>
        </div>
    );
};

Footer.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default connect(stateToProps)(Footer);
