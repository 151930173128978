const URL = 'https://api-obm24.layerzerox.com';
export const MARKETPLACE_URL = 'https://omniflix.market';

export const SIGN_IN_URL = `${URL}/login`;
export const SIGN_IN_VERIFY_URL = `${URL}/verify-login`;
export const ACCESS_TOKEN_URL = `${URL}/auth/refresh-token`;
export const CREATE_ORDER_URL = `${URL}/orders`;
export const FETCH_TICKET_TYPE_URL = `${URL}/ticket-types`;
export const PROFILE_DETAILS_FETCH_URL = `${URL}/me/profile/details`;
export const PROFILE_IMAGE_FETCH_URL = `${URL}/me/profile/image`;
export const STRIP_SECRET_FETCH_URL = `${URL}/stripe/payment-intent`;
export const STATS_FETCH_URL = `${URL}/my/stats`;
export const CAPTCHA_VERIFY_URL = `${URL}/captcha`;
export const SUPPORT_URL = `${URL}/support-forms`;

export const urlFetchTickets = (skip, limit, claimStatuses) => {
    const params = ['sortBy=created_at', 'order=desc'];
    if (claimStatuses) {
        params.push('claimStatuses[]=CLAIMED');
    }
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }

    return `${URL}/my/tickets?${params.join('&')}`;
};

export const urlFetchOrders = (skip, limit, sortBy, order) => {
    const params = [];
    if (skip) {
        params.push(`skip=${skip}`);
    }
    if (limit) {
        params.push(`limit=${limit}`);
    }
    if (sortBy && sortBy.length > 0) {
        params.push(`sortBy=${sortBy}`);

        if (order) {
            params.push(`order=${order}`);
        }
    } else {
        params.push('sortBy=created_at');
        params.push('order=desc');
    }

    return `${URL}/my/orders?${params.join('&')}`;
};
export const urlFetchMyOrder = (id) => {
    return `${URL}/my/orders/${id}`;
};
export const urlFetchCouponCodeData = (id) => {
    return `${URL}/coupons/${id}`;
};
export const urlCancelTicket = (id) => {
    return `${URL}/my/ordered-tickets/${id}/cancel`;
};
export const urlDownloadTicket = (ticketType, id) => {
    return `${URL}/my/${ticketType}/${id}/download`;
};
export const urlFetchQRCode = (id) => {
    return `${URL}/my/tickets/${id}/qr-download`;
};
export const urlClaimStatus = (id) => {
    return `${URL}/my/tickets/${id}/events/632954eb02c34818767c5434/gated-accesses/632955df02c34818767c5989/entry-status`;
};
export const urlClaimNFT = (id) => {
    return `${URL}/my/tickets/${id}/claim-nft`;
};
export const urlFetchTicket = (id) => `${URL}/my/tickets/${id}`;

export const urlUpdateBCAccount = () =>
    `${URL}/me/update-bc-account`;
