export const config = {
    RPC_URL: 'https://rpc-osmosis.streamswap.io',
    REST_URL: 'https://lcd-osmosis.streamswap.io',
    CHAIN_ID: 'osmosis-1',
    CHAIN_NAME: 'Osmosis',
    COIN_DENOM: 'OSMO',
    COIN_MINIMAL_DENOM: 'uosmo',
    COIN_DECIMALS: 6,
    PREFIX: 'osmo',
    COINGECKO_ID: 'osmosis',
};

export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 10;
export const DEFAULT_SORT_BY = 'created_at';
export const DEFAULT_ORDER = 'desc';
export const DEFAULT_LAZY_FETCH_HEIGHT = 256;

export const chainId = config.CHAIN_ID;
export const chainName = config.CHAIN_NAME;
export const coinDenom = config.COIN_DENOM;
export const coinMinimalDenom = config.COIN_MINIMAL_DENOM;
export const coinDecimals = config.COIN_DECIMALS;
export const prefix = config.PREFIX;

export const walletExtensions = {
    LEAP: 'https://chrome.google.com/webstore/detail/leap-cosmos-wallet/fcfcfllfndlomdhbehjjcoimbgofdncg',
    COSMO_STATION: 'https://chrome.google.com/webstore/detail/cosmostation-wallet/fpkhgmpbidmiogeglndfbkegfdlnajnf',
    KEPLR: 'https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap',
};

export const chainConfig = {
    chainId: chainId,
    chainName,
    rpc: config.RPC_URL,
    rest: config.REST_URL,
    stakeCurrency: {
        coinDenom,
        coinMinimalDenom,
        coinDecimals,
    },
    bip44: {
        coinType: 118,
    },
    bech32Config: {
        bech32PrefixAccAddr: `${prefix}`,
        bech32PrefixAccPub: `${prefix}pub`,
        bech32PrefixValAddr: `${prefix}valoper`,
        bech32PrefixValPub: `${prefix}valoperpub`,
        bech32PrefixConsAddr: `${prefix}valcons`,
        bech32PrefixConsPub: `${prefix}valconspub`,
    },
    currencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
        },
    ],
    feeCurrencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
            gasPriceStep: {
                low: 0.025,
                average: 0.03,
                high: 0.04,
            },
        },
    ],
    coinType: 118,
};

export const flixConfig = {
    RPC_URL: 'https://rpc.omniflix.network',
    REST_URL: 'https://rest.omniflix.network',
    CHAIN_ID: 'omniflixhub-1',
    CHAIN_NAME: 'OmniFlix Hub',
    COIN_DENOM: 'FLIX',
    COIN_MINIMAL_DENOM: 'uflix',
    COIN_DECIMALS: 6,
    PREFIX: 'omniflix',
};

export const flixChainConfig = {
    chainId: flixConfig.CHAIN_ID,
    chainName: flixConfig.CHAIN_NAME,
    rpc: flixConfig.RPC_URL,
    rest: flixConfig.REST_URL,
    stakeCurrency: {
        coinDenom: flixConfig.COIN_DENOM,
        coinMinimalDenom: flixConfig.COIN_MINIMAL_DENOM,
        coinDecimals: flixConfig.COIN_DECIMALS,
    },
    bip44: {
        coinType: 118,
    },
    bech32Config: {
        bech32PrefixAccAddr: `${flixConfig.PREFIX}`,
        bech32PrefixAccPub: `${flixConfig.PREFIX}pub`,
        bech32PrefixValAddr: `${flixConfig.PREFIX}valoper`,
        bech32PrefixValPub: `${flixConfig.PREFIX}valoperpub`,
        bech32PrefixConsAddr: `${flixConfig.PREFIX}valcons`,
        bech32PrefixConsPub: `${flixConfig.PREFIX}valconspub`,
    },
    currencies: [
        {
            coinDenom: flixConfig.COIN_DENOM,
            coinMinimalDenom: flixConfig.COIN_MINIMAL_DENOM,
            coinDecimals: flixConfig.COIN_DECIMALS,
        },
    ],
    feeCurrencies: [
        {
            coinDenom: flixConfig.COIN_DENOM,
            coinMinimalDenom: flixConfig.COIN_MINIMAL_DENOM,
            coinDecimals: flixConfig.COIN_DECIMALS,
        },
    ],
    coinType: 118,
    gasPriceStep: {
        low: 0.001,
        average: 0.0025,
        high: 0.025,
    },
};
